body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI, Roboto", "Oxygen","Ubuntu",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
    body {
    background-color: #1f2833;
    }
    .particlesheader {
    font-size: 4em;
    margin-top: 350px;
    text-align: center;
    color: #66fcf1
    }

@tailwind base;
@tailwind components;
@tailwind utilities;